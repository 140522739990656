import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {getSchoolStats} from 'helpers/stats-helper';
import {loginUiTexts, schoolUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Logo from '../ui/logo/logo';
import Logos from 'components/ui/logos/logos';
import PopupVideo from './popup-video/popup-video';
import PopupCreateGame from './popup-create-game/popup-create-game';
import PopupDeleteGame from './popup-delete-game/popup-delete-game';
import './landing-page-school.scss';

const LandingPageSchool = (
	{
		scenarioId, 
		games, 
		groups,
		schoolData, 
		goToGame,
		createGame,
		deleteGame, 
		handleLogout, 
		updateBackground 
	}
) => {
	/* Video popup */
	const [showVideoPopup, setShowVideoPopup] = useState(false);

	/* Create game popup */
	const [showPopupCreateGame, setShowPopupCreateGame] = useState(false);

	/* Delete game popup */
	const [deleteGameId, setDeleteGameId] = useState(null);

	/* Stats */
	const {playthroughCount, studentCount} = getSchoolStats(schoolData, games, groups);

	useEffect(() => {
		// Set background image id
		updateBackground('login', scenarioId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<div className={'LandingPageSchool'}>
			<Logos />
			<div className='LandingPageSchool-logoWrapper'>
				<Logo scenarioId={scenarioId} page="landing" />
			</div>
			<div className="LandingPageSchool-content">
				<div className='LandingPageSchool-schoolName'>
					<span>{schoolData.name}</span>
				</div>
				<div className='LandingPageSchool-schoolStats'>
					<div className='LandingPageSchool-studentCount'>
						<span>{schoolUiTexts.landingPage.studentsField} {studentCount}</span>
					</div>
					<span>
						{'|'}
					</span>
					<div className='LandingPageSchool-playthroughCount'>
						<span>{schoolUiTexts.landingPage.playThroughField} {playthroughCount}</span>
					</div>
				</div>

				<div className='LandingPageSchool-createGameButton'>
					<Button
						classes={['login']}
						text={schoolUiTexts.landingPage.createGame}
						onClick={() => {setShowPopupCreateGame(true);}}
					/>
				</div>

				{/* Games */}
				<div className='LandingPageSchool-games'>
					<div className='LandingPageSchool-row header'>
						<div className='LandingPageSchool-cell'>
							<span>{schoolUiTexts.landingPage.game}</span>
						</div>
						<div className='LandingPageSchool-cell'>
							<span>{schoolUiTexts.landingPage.gameCode}</span>
						</div>
						<div className='LandingPageSchool-cell center'>
							<span>{schoolUiTexts.landingPage.choose}</span>
						</div>
						<div className='LandingPageSchool-cell center delete'>
							<span>{schoolUiTexts.landingPage.delete}</span><span></span>
						</div>
					</div>

					{games && games.map((game, index) => {
					
						return (
							<div key={index} className='LandingPageSchool-row body'>
								<div className='LandingPageSchool-cell'>
									<span>{game.name}</span>
								</div>
								<div className='LandingPageSchool-cell'>
									<span>{game.id}</span>
								</div>
								<div className='LandingPageSchool-cell'>
									<Button
										classes={['flatBlueLight']}
										text={schoolUiTexts.landingPage.goToGame}
										onClick={() => {goToGame(game.id);}}
									/>
								</div>
								<div 
									className="LandingPageSchool-cell delete" 
									onClick={() => {setDeleteGameId(game.id);}}
								/>
							</div>
						);
					})}
				</div>
			</div>


			{/* Video button */}
			<div className="LandingPageSchool-videoBtn">
				<Button 
					classes={['login', 'video']} 
					text={schoolUiTexts.video}
					onClick={() => {setShowVideoPopup(true);}}
				/>
			</div>

			<div className="LandingPageSchool-manualBtn">
				<Button 
					isDisabled={true} // Disabled until manual is finished
					text={schoolUiTexts.downloadManual}
					classes={['login', 'download']}
					onClick={() => {}}
				/>
			</div>

			{/* Logout button */}
			<div className="LandingPageSchool-logoutBtn">
				<Button 
					classes={['login', 'logout']} 
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Back to select game btn */}
			<div className="LandingPageSchool-backBtn">
				<Button 
					classes={['backBtnGame', scenarioId]} 
					text={schoolUiTexts.back}
					type="a"
					href="/"
				/>
			</div>

			{/* Video popup */}
			{showVideoPopup && <PopupVideo closePopup={() => {setShowVideoPopup(false);}}/>}

			{/* Create Game Popup */}
			{showPopupCreateGame &&
				<PopupCreateGame
					createGame={createGame}
					closePopup={() => {setShowPopupCreateGame(false);}}
				/>
			}

			{deleteGameId && 
				<PopupDeleteGame 
					gameId={deleteGameId}
					games={games}
					deleteGame={deleteGame}
					closePopup={() => {setDeleteGameId(null);}}
				/>}
		</div>
	);
};

LandingPageSchool.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	games: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	schoolData: PropTypes.object.isRequired,
	goToGame: PropTypes.func.isRequired,
	createGame: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired
};

export default LandingPageSchool;
