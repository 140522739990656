import appConfig from 'config/app.config';

const getSchoolStats = (schoolData, games, groups) => {
	let studentCount = 0;
	let playthroughCount = 0;

	/* Get stats from deleted games */
	if (schoolData && schoolData.gameStats && schoolData.gameStats.length > 0) {
		schoolData.gameStats.forEach((gameStat) => {
			playthroughCount += 1;
			if (gameStat.players && gameStat.players > 0) {
				studentCount += gameStat.players;
			}
		});
	}

	/* Get stats from existing games */
	if (games && games.length > 0) {
		games.forEach((game) => {
			const startTime = (game.startTime ? game.startTime : null); // miliseconds
			const currentTime = Date.now(); // miliseconds
			const endTime = startTime + (appConfig.gameDurationMinutes * 60000);
			const isDone = (
				(startTime && currentTime > endTime) ||
				game.status === 'done'
			);
			if (isDone) {
				const activeGroups = groups.filter((g) => {
					return (
						g.gameId === game.id &&
						g.assignments && 
						g.assignments.length > 1
					);
				});
				if (activeGroups.length > 0) {
					playthroughCount += 1;
					if (game.studentCount) {
						studentCount += parseInt(game.studentCount);
					}
				}
			}
		});
	}

	return {playthroughCount, studentCount};
};


export {
	getSchoolStats
};