import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { schoolUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import Countdown from 'components/ui/countdown/countdown';
import Leaderboard from 'components/ui/leaderboard/leaderboard';
import PopupGameStatusWarning from 'components/ui/popup-game-status-warning/popup-game-status-warning';
import GameCode from 'components/ui/game-code/game-code';
import './game-open.scss';
import { soundsData } from 'data/sound-data';

const GameOpen = ({schoolData, gameGroups, updateGame, handleGoToPage, updateBackground, game, handlePlayMusic }) => {
	useEffect(() => {
		// Set background image id
		updateBackground('school', game.scenarioId);
		handlePlayMusic(false, soundsData.login);
		
		return () => {
			handlePlayMusic(false, null);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Track if show/hide leaderboard */
	const [showLeaderboard, setShowLeaderboard] = useState(false);
 
	/* Track if show/hide warning popup */
	const [showStartGameWarningPopup, setShowStartGameWarningPopup] = useState(false);

	return (
		<div className="GameOpen">
			{/* Logo */}
			<div className="GameOpen-logo" onClick={() => {handleGoToPage('landing');}} >
				<Logo scenarioId={game.scenarioId} />
			</div>

			{/* School name */}
			{<div className="GameOpen-schoolName">
				<span>{schoolData.name}</span>
			</div>}

			{/* Game code */}
			<GameCode page={'game-open' + (showLeaderboard ? ' top' : '')} game={game}/>

			{/* Countdown timer */}
			<Countdown 
				startTime={game.startTime}
				layout={showLeaderboard ? 'small' : 'default'}
				minutes={appConfig.gameDurationMinutes}
			/>

			{/* Leaderboard  */}
			<div className={'GameOpen-leaderBoard' + (showLeaderboard ? '' : ' hidden')}>
				<Leaderboard 
					status="open" 
					gameGroups={gameGroups} 
					setShowLeaderboard={setShowLeaderboard} 
					scenarioId={game.scenarioId} 
				/>
			</div>
			

			<div className="GameOpen-buttons">
				{/* Back to landing page btn */}
				<div className="GameOpen-backBtn">
					<Button 
						classes={['blueLight', game.scenarioId, 'backBtn', 'responsive']}
						text={schoolUiTexts.back}
						onClick={() => {handleGoToPage('landing');}}
					/>
				</div>

				{/* Toggle highscore btn */}
				<div className="GameOpen-highscoreBtn">
					<Button 
						classes={[
							'blueLight', 
							game.scenarioId, 
							'highscoreBtn', 
							'responsive ' + (showLeaderboard ? 'countdown' : 'trophy')
						]}
						text={(showLeaderboard ? schoolUiTexts.countdownTimer : schoolUiTexts.leaderboard)}
						onClick={() => {setShowLeaderboard(!showLeaderboard);}}
					/>
				</div>

				{/* Close game btn */}
				<div className="GameOpen-closeBtn">
					<Button 
						classes={['blueLight', game.scenarioId, 'responsive']}
						text={schoolUiTexts.closeGameBtn}
						onClick={() => {setShowStartGameWarningPopup(true);}}
					/>
				</div>
			</div>
			



			{/* Warning popup (confirm close game) */}
			{showStartGameWarningPopup &&
				<PopupGameStatusWarning 
					newStatus='done'
					updateGame={updateGame}
					closePopup={() => {setShowStartGameWarningPopup(false);}}
				/>}
		</div>
	);
};

GameOpen.propTypes = {
	schoolData: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	gameGroups: PropTypes.array.isRequired,
	updateGame: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
	handlePlayMusic: PropTypes.func.isRequired,
};

export default GameOpen;
