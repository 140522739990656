import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {loginUiTexts} from 'data/ui-texts';
import {scenariosData} from 'data/scenarios-data';
import { checkDataForUndefined } from 'helpers/debug-helper';
import Loading from 'components/loading/loading';
import LandingPageGroup from 'components/landing-page-group/landing-page-group';
import Game from 'components/game/game';

class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			page: 'landing', // landing, game
			group: null,
			groups: [],
			game: null,
			schoolData: null
		};
		this.timeout = null;
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
		this.unsubscribeGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to group and game */
		Promise.all([
			this.subscribeToGame(), 
			this.subscribeToGroup()
		]).then((responses) => {
			if (responses[0].status === 'ok' && responses[1].status === 'ok') {
				this.getSchoolData().then(() => {
					this.setState({isLoading: false});
					if (!this.state.group.isPlaying) {
						this.updateGroup({isPlaying: true});
					}
					this.subscribeToGroups();
				});
				
			} else {
				console.error(responses);
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / group */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();

		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	};

	/**
	 * Subscribe to own group
	 * @param {string} groupId
	 */
	subscribeToGroup = () => {
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();

		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGroup = db.collection(appConfig.groupsDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					const group = {id: doc.id, ...doc.data()};
					// let prevGroupData = this.state.group;
					this.setState({group: group}, () => {
						// this.checkGroupUpdates(prevGroupData);
						resolve({ status: 'ok' });
					});
				} else {
					this.props.handleLogout();	
				}
			},
			(error) => {
				console.error('could not get group: ', error);
				this.props.handleLogout();
			}
			);
		});
	};

	/**
	 * Subscribe to game
	 */
	subscribeToGame = () => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		const gameId = this.props.userId.substr(0, this.props.userId.indexOf('-'));
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGame = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists && doc.data().scenarioId && scenariosData.hasOwnProperty(doc.data().scenarioId)) {
					if (doc.data().scenarioId !== this.props.scenarioId) {
						/* Redirect to correct URL */
						const url = scenariosData[doc.data().scenarioId].url;
						window.location.href = '/' + url;
					} else {
						/* Subscribe to game */
						const game = {id: doc.id, ...doc.data()};
						const prevGameData = this.state.gameData;
						this.setState({ game: game }, () => {
							this.checkGameUpdates(prevGameData);
							resolve({ status: 'ok' });
						});
					}
				} else {
					/* No game, probably deleted: log out group */
					this.props.handleLogout();
				}
			},
			(error) => {
				/* Error: game not found */
				console.error('could not get game: ', error);
				resolve({ status: 'error', error: error });
			}
			);
		});
	};

	/**
	 * Subscribe to all groups
	 */
	 subscribeToGroups = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		
		const gameId = this.state.game.id;
		return new Promise((resolve, reject) => {
			let db = firebase.firestore();
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({ groups: groups }, () => {
						resolve();// resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					reject(error);
				}
			);
		});
	};

	getSchoolData = () => {
		return new Promise((resolve, reject) => {
			if (this.state.game && this.state.game.schoolId) {
				const db = firebase.firestore();
				db.collection(appConfig.schoolsDbName).doc(this.state.game.schoolId).get().then((schoolDoc) => {
					if (schoolDoc.exists && schoolDoc.data()) {
						const schoolData = {id: schoolDoc.id, ...schoolDoc.data()};
						this.setState({schoolData}, () => {
							resolve();
						});
					} else {
						resolve();
					}
				});
			} else {
				resolve();
			}
		});
	};

	/**
	 * Check game updates
	 * @param {object} prevGameData
	 */
	checkGameUpdates = (prevGameData) => {
		/* School opened phase 2 */
		// if (prevGameData && this.state.gameData && prevGameData.phaseId !== this.state.gameData.phaseId) {
		// 	let roundData = roundsData.find((round) => {return round.id === this.state.groupData.roundId;});
		// 	if (roundData && roundData.showIntroPopup === true && this.state.gameData.phaseId === roundData.phaseId) {
		// 		this.setState({showIntroPopup: true});
		// 	}
		// }
	};

	/**
	 * Update group
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGroup = (updates) => {
		if (checkDataForUndefined(updates)) {
			console.log(updates);
		}
		const groupId = this.props.userId;
		const db = firebase.firestore();
		const groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};

	/**
	 * Reset game progress
	 * @returns {promise}
	 */
	resetGameProgress = () => {
		const groupId = this.props.userId;
		const db = firebase.firestore();
		const groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update({assignments: []});
	};

	/**
	 * Go to page
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		this.setState({page: page});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={loginUiTexts.loadingGame}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};
		

		/* Game */
		if (this.state.page === 'game' && this.state.game.status === 'open') {
			return (
				<Game 
					scenarioId={this.props.scenarioId}
					game={this.state.game}
					schoolData={this.state.schoolData}
					group={this.state.group}
					groups={this.state.groups}
					handleGoToPage={this.handleGoToPage}
					updateGroup={this.updateGroup}
					updateBackground={this.props.updateBackground}
				/>
			);
		}

		/* Landing page */
		return (
			<LandingPageGroup 
				game={this.state.game}
				schoolData={this.state.schoolData}
				scenarioId={this.props.scenarioId}
				resetGameProgress={this.resetGameProgress}
				handleGoToPage={this.handleGoToPage}
				handleLogout={this.props.handleLogout}
				updateBackground={this.props.updateBackground}
			/>
		);


	};
}

GroupController.propTypes = {
	scenarioId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateBackground: PropTypes.func.isRequired,
};

export default GroupController;
